.home {
  width: 90%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-body {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  gap: 3rem;
  flex-wrap: wrap;
}

.home-body-section {
  width: 100%;
  max-width: 500px;
}

.home-title {
  font-size: 48px;
}

.home-text {
  font-size: 24px;
}
