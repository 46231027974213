.navbar {
  width: 100vw;
  height: 75px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.navbar-brand {
  height: 50px;
  margin-left: 30px;
}

.navbar-nav {
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
