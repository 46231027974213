.choices {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 5%;
}

.choice {
  width: 95%;
  background-color: #393E46;
  color: #EEEEEE;
  padding: 8px 10px;
  font-size: 18px;
  border: 1px solid #7A88F2;
  border-radius: 10px;
}

.choice-selected {
  background-color: #7A88F2;
  font-weight: 600;
}

.choice:hover {
  background-color: #7A88F2;
  font-weight: 600;
  cursor: pointer;
}
