.loan-slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
}

.loan-slider-range {
  width: 100%;
  -webkit-appearance: none;
  height: 5px;
  border-radius: 6px;
  background: #f1f1f1;
  outline: none;
  padding: 0;
  margin: 0;
}

.loan-slider-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #7A88F2;
  border: #7A88F2 5px solid;
  cursor: ew-resize;
  -webkit-transition: background .15s ease-in-out;
  transition: background .15s ease-in-out;
}

.loan-slider-amounts {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.loan-slider-amount-container {
  display: flex;
  flex-direction: column;
  gap: 0px;
  text-align: left;
}

.loan-slider-amount-container-right {
  display: flex;
  flex-direction: column;
  gap: 0px;
  text-align: right;
}

.loan-slider-amount {
  margin-bottom: 0px;
  font-size: 36px;
}

.loan-slider-amount-label {
  margin-top: 5px;
  font-size: 18px;
}

.loan-slider-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.loan-slider-stat-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
}

.loan-slider-stat-label {
  font-size: 18px;
  margin-bottom: 0px;
}

.loan-slider-stat {
  font-size: 24px;
  margin-top: 2px;
}

.divider {
  width: 1px;
  height: 50px;
  background-color: #EEEEEE;
}
