.content {
  min-height: 90vh;
  background-color: #222831;
  color: #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.w-100 {
  width: 100%;
}

.btn {
  background-color: #7A88F2;
  padding: 8px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #EEEEEE;
  text-decoration: none;
  border: none;
}

.btn-muted {
  background-color: #393E46;
  color: #222831;
}

.btn-sm {
  font-size: 18px;
}

.btn:hover {
  background-color: #7a88f2d0;
  cursor: pointer;
}

.btn-muted:hover {
  background-color: #393E46;
  cursor: default;  
}
